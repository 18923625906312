<template>
    <ion-app>
        <ion-split-pane content-id="main-content" when="xl" disabled="true">
            <ion-menu side="start" contentId="main-content" type="overlay" v-if="usuario._id">
                <ion-header>
                    <ion-toolbar color="primary">
                        <ion-buttons :slot="!esMovil ? 'start' : 'end'">
                            <ion-menu-button v-if="!esMovil" />
                            <IonMenuToggle v-else>
                                <IonButton>
                                    <IonIcon :icon="close"></IonIcon>
                                </IonButton>
                            </IonMenuToggle>
                        </ion-buttons>
                        <ion-title @click="inicio;
                        clog(usuario);
                                                                                                              ">{{ appName }}</ion-title>
                    </ion-toolbar>
                    <ion-progress-bar :color=" danger " v-if=" cargando " type="indeterminate"></ion-progress-bar>
                </ion-header>
                <ion-content color="light" class="ion-padding">
                    <div class="fondoEncorp" />
                    <ion-item color="light" lines="none">
                        <ion-thumbnail slot="start">
                            <ion-img :src="
                                usuario.imagen
                                    ? urlize(usuario.imagen)
                                    : 'encorp/48w/encorp00.png'
                            " class="iconoheader" />
                        </ion-thumbnail>
                        <ion-label text-wrap v-if="
                            usuario.rol != 'administrador' &&
                                usuario.rolOriginal != 'administrador'
                        ">
                            <h2 class="ion-text-capitalize">
                                {{ usuario.nombre }}
                            </h2>
                            <p class="ion-text-capitalize">
                                {{ usuario.rol }} {{ usuario.rolOriginal }}
                                <span v-if=" usuario.rol == 'cliente' && coto?._id ">, {{ coto.nombre }}
                                </span>
                            </p>
                        </ion-label>
                        <IonSelect v-else :interface=" esMovil ? 'popover' : '' " :label=" usuario.nombre "
                            label-placement="floating" v-model=" usuario.rol ">
                            <IonSelectOption :value=" 'administrador' ">
                                Ver como Administrador
                            </IonSelectOption>
                            <IonSelectOption :value=" 'proveedor' ">
                                Ver como Proveedor
                            </IonSelectOption>
                            <IonSelectOption :value=" 'vigilante' ">
                                Ver como Vigilancia
                            </IonSelectOption>
                        </IonSelect>
                        <IonIcon slot="end" v-if=" usuario.oneSignalIds?.length > 0 " :icon=" megaphoneOutline "></IonIcon>
                    </ion-item>
                    <HbMenu :usuario=" usuario " :empresa=" empresa " :coto=" coto " :menusDinamicos=" menusDinamicos "
                        @salir=" logout " />
                </ion-content>
            </ion-menu>
            <ion-router-outlet id="main-content"></ion-router-outlet>
        </ion-split-pane>
    </ion-app>
</template>
<style>
@media (max-width: 768px) {
    ion-menu {
        --width: 100vw;
        --min-width: 100vw;
    }
}

@media (min-width: 769px) {
    ion-menu {
        --width: 400px;
        --min-width: 400px;
    }
}

.fondoEncorp {
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-image: url(https://app.encorp.mx/encorp/SVG/encorp00.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    opacity: 20%;
}
</style>
<script>
import Api from "@/components/Api/Api";
import sha1 from "sha1";
const usuario = Api.fetchLocal("yo") ?? {};
const empresa = Api.fetchLocal("empresadb") ?? {};
if (!usuario.permisosCotos) {
    usuario.permisosCotos = [];
}
if (!usuario.permisosModulos) usuario.permisosModulos = {};
import HbMenu from "@/components/Menu/HbMenu";
//import { DeviceUUID } from "device-uuid";

// Mejorar los mensajes de depuración
if (Api.modoProduccion) {
    // Eliminar los CLOGs sólo en producción
    console.log("Modo producción. Enmudeciendo console.log");
    console.log2 = console.log;
    // eslint-disable-next-line
    console.log = function () { };
    // Enviar los logs de error a la bitácora, pero sólo con conexión
}
console.merror = console.error;
import {
    IonApp,
    toastController,
    IonMenuButton,
    IonButtons,
    IonSplitPane,
    IonRouterOutlet,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonItem,
    IonImg,
    IonLabel,
    IonProgressBar,
    IonMenuToggle,
    IonIcon,
    IonButton,
    alertController,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
} from "@ionic/vue";
import {
    closeCircleOutline,
    logOut,
    close,
    megaphoneOutline,
} from "ionicons/icons";
// Sobreescribir la función alert default
window.toast = async (
    message = "",
    duration = 2000,
    color = "secondary",
    position = "top"
) => {
    if (isNaN(duration)) duration = 2000;
    const toast = await toastController.create({
        message,
        duration,
        color,
        position,
        //icon: informationCircleOutline,
        buttons: [
            {
                icon: closeCircleOutline,
                role: "cancel",
                handler: () => {
                    console.log("Cancel clicked");
                },
            },
        ],
    });
    await toast.present();
};
window.alert2 = window.alert;
window.alert = async function (
    message = "",
    header = "Aviso",
    okButton = "OK"
) {
    try {
        const alert = await alertController.create({
            cssClass: "my-custom-class",
            header,
            message,
            buttons: [okButton],
        });
        await alert.present();
    } catch (e) {
        return window.alert2(message);
    }
};
//

import { defineComponent } from "vue";
//
import { Capacitor } from "@capacitor/core";
//
export default defineComponent({
    name: "App",
    components: {
        HbMenu,
        IonApp,
        IonMenuButton,
        IonSplitPane,
        IonRouterOutlet,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonMenu,
        IonContent,
        IonItem,
        IonThumbnail,
        IonImg,
        IonLabel,
        IonProgressBar,
        IonMenuToggle,
        IonIcon,
        IonButton,
        IonSelect,
        IonSelectOption,
    },
    data() {
        return {
            appName: Api.appName,
            servidor: Api.servidor,
            esMovil: Api.esMovil(),
            esBdLocal: localStorage.getItem("engine") == "localStorage",
            usuario,
            empresa,
            coto: {},
            cargando: false,
            ladoMenu: "left",
            publicidad:
                "muestras/Plantilla publicitaria " +
                (window.innerWidth > 540 ? "vertical" : "horizontal") +
                ".png",
            publicidadLink: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
            menusDinamicos: [],
            //
            logOut,
            close,
            megaphoneOutline,
        };
    },
    methods: {
        urlize: Api.urlize,
        clog: console.log,
        goTo(lugar) {
            this.$router.push(lugar);
        },
        img(str) {
            return str.startsWith("http") || str.startsWith("data")
                ? str
                : this.cdn + "/" + str;
        },
        async openEnd() {
            let u = document.querySelector("ion-menu-controller");
            console.log("Abriendo menú", u);
            u.enable(true, "default");
            u.open("default");
            this.calcularMenusDinamicos();
        },
        closeEnd() {
            this.query = "";
            let u = document.querySelector("ion-menu-controller");
            u.close("default");
        },
        inicio() {
            console.log({ usuario: this.usuario });
            this.$router.push(
                this.usuario.rol == "administrador" ? "/hola" : "/uxcliente"
            );
        },
        async logout(noRecargar = false) {
            console.log("$ LOGOUT!");
            this.cargando = true;
            const borrarDatosLocales = true;
            if (borrarDatosLocales) {
                localStorage.setItem("modoOffline", 0);
                localStorage.setItem("catalogosInicializados", false);
                Api.deleteLocal("productos");
                Api.deleteLocal("branding");
                Api.deleteLocal("menusDinamicos");
                Api.deleteLocal("menusDinamicosIndices");
                if (!Api.esBdLocal) Api.deleteLocal("empresadb");
                const catalogosBorrables = [
                    "producto",
                    "almacen",
                    "familia",
                    "directorio", // Primarios, inamovibles excepto por un Administrador
                    "metodopago",
                    "lineaprecio",
                    "grupocaja",
                    "plantilladocx", // Auxiliares, el business logic puede seguir sin ellos
                    "proveedor",
                    "cliente", // Manipulables por el punto de venta
                    "proyecto",
                    "partida",
                    "partidaoc",
                    "pago",
                    "facturama", // Las ventas per se
                ];
                for (let key in localStorage)
                    for (let catalogo of catalogosBorrables)
                        if (key.startsWith("" + catalogo + ".")) {
                            Api.deleteLocal(key);
                            localStorage.removeItem(key);
                        }
                Api.deleteLocal("modulos");
            }
            Api.deleteLocal("yo");
            await Api.wait(100);
            this.$router.push("/login"); // Manual
            this.cargando = false;
            await Api.wait(100);
            window.location.reload();
        },
        toggleModoDark(val) {
            this.modoDark = val;
            document.body.classList.toggle("dark", val);
            Api.saveLocal("modoDark", val == true ? "si" : "no");
        },
        /* async promptActualizarServiceWorker() {
          if (usuario._id)
            try {
              const installingWorker =
                window.serviceWorkerCurrentRegistration.installing;
              console.info(
                "Actualiando service worker? installingWorker",
                installingWorker
              );
              installingWorker.onstatechange = (_) => {
                console.info("serviceWorker onstatechange", installingWorker.state);
                return new Promise((resolve) => {
                  switch (installingWorker.state) {
                    case "installed":
                      if (navigator.serviceWorker.controller) {
                        const prmpt = confirm(
                          "Se ha encontrado una actualización de la app. ¿Desea actualizar ahora?\n\nDeberá volver a iniciar sesión"
                        );
                        if (true == prmpt) {
                          const catalogosBorrables = [
                            "producto",
                            "familia",
                            "cliente",
                            "proveedor",
                          ];
                          for (const catalogo of catalogosBorrables)
                            for (const key in localStorage)
                              if (key.startsWith("" + catalogo)) {
                                console.log("Borrando localmente", key);
                                Api.deleteLocal(key);
                              }
                          Api.deleteLocal("yo");
                        }
                        window.location.reload();
                        resolve(true);
                      } else {
                        // no update available
                        resolve(false);
                      }
                      break;
                    default:
                      break;
                  }
                });
              };
            } catch (e) {
              console.merror(
                "No se pudo pedir la actualizacion del Service Worker al usuario"
              );
              console.merror(e);
            }
        }, //*/
        calcularMenusDinamicos() {
            const menusDinamicosIndices =
                Api.fetchLocal("menusDinamicosIndices") || {};
            this.menusDinamicos = Object.values(menusDinamicosIndices).sort((a, b) =>
                a.cardinality > b.cardinality ? 1 : -1
            );
            console.log("calcularMenusDinamicos?", this.menusDinamicos);
            for (let i in this.menusDinamicos)
                this.menusDinamicos[i].routes =
                    this.menusDinamicos[i].routes ||
                    [].map((u) => {
                        if (u.component) delete u.component;
                        if (u.children)
                            u.children = u.children.map((c) => {
                                if (c.component) delete c.component;
                                return c;
                            });
                        return u;
                    });
            Api.saveLocal("modulos", this.menusDinamicos);
        },
        async comprobarCerrarSesion() {
            // Primero comprobar la sesión actual
            const rutasPermitidas = ["registro", "login"];
            const rutaActual = this.$route.path;
            let estoyEnRutaPermitida = false;
            for (const r of rutasPermitidas)
                if (rutaActual.startsWith(`/${r}`)) estoyEnRutaPermitida = true;
            const estoyAutenticado = usuario.jwt?.length > 0;
            console.log({ estoyAutenticado, estoyEnRutaPermitida, rutaActual });
            if (estoyAutenticado) {
                // IR a la ruta del diccionario
                const diccionarioRutas = {
                    default: "/hola",
                    administrador: "/admin/anuncios",
                    cliente: "/uxcliente/es",
                    vigilante: "/uxcliente/es",
                };
                if (rutaActual == "/")
                    this.$router.push(
                        diccionarioRutas[usuario.rol] ?? diccionarioRutas.default
                    );
                else if (rutaActual == "/login") {
                    console.log("Pasale, morro");
                    await Api.wait(500);
                    this.$router.push("/");
                } else {
                    console.log("Hola de nuevo, compa", usuario.nombre.toUpperCase());
                }
            } else if (!estoyEnRutaPermitida) {
                // Saaaacalo, hijo, saaacalo!
                console.log("Pa fuera");
                this.$router.push("/login");
            }

            // Luego comprobar si hay que cerrarla por comando externo
            try {
                const solicitudesActivas = (
                    await Api.find("solicitudcerrarsesion", [
                        "idDirectorio,eq," + this.usuario._id,
                    ])
                ).filter((s) => s.ejecutada == 0);
                console.log("comprobarCerrarSesion?", solicitudesActivas);
                if (solicitudesActivas.length > 0) {
                    const sol = solicitudesActivas[0];
                    sol.ejecutada = new Date().getTime();
                    await Api.save("solicitudcerrarsesion", sol);
                    alert("Por favor inicie sesión nuevamente");
                    await Api.wait(2000);
                    this.logout();
                }
            } catch (e) {
                console.error("comprobarCerrarSesion", e.message || e);
                const yo = Api.fetchLocal("yo") || {};
                if (
                    yo?._id &&
                    e.message &&
                    e.message.toLowerCase().indexOf("request failed") >= 0
                )
                    this.logout();
            }
        },
    },
    async mounted() {
        console.log("Initializing HomePage");
        await Api.wait(50);
        this.comprobarCerrarSesion();
        //
        if (Capacitor.getPlatform() !== "web")
            try {
                const { PushNotifications } = await import(
                    "@capacitor/push-notifications"
                );
                PushNotifications.requestPermissions().then((result) => {
                    if (result.receive === "granted") {
                        PushNotifications.register();
                    } else {
                        // Show some error
                    }
                });

                // On success, we should be able to receive notifications
                PushNotifications.addListener("registration", (token) => {
                    window.toast("Habilitado para notificaciones ");
                    console.log("TOKEN de notificaciones", token);
                    Api.save("directorionotificable", {
                        _id: sha1(usuario._id + token).substring(0, 24),
                        idUsuario: usuario._id,
                        idCoto: usuario.idCoto,
                        token,
                        fecha: new Date().getTime(),
                    });
                });

                // Some issue with our setup and push will not work
                PushNotifications.addListener("registrationError", (error) => {
                    if (Api.esMovil())
                        window.toast(
                            "Error de registro para notificaciones ", 2500, 'error'
                        );
                    else
                        window.alert(
                            "Error de registro para notificaciones: " + JSON.stringify(error)
                        );
                });

                // Show us the notification payload if the app is open on our device
                PushNotifications.addListener(
                    "pushNotificationReceived",
                    (notification) => {
                        try {
                            window.alert("ENCORP notifica: " + notification.body);
                        } catch (e) {
                            console.error(e);
                            window.alert("ENCORP notifica: " + JSON.stringify(notification));
                        }
                    }
                );

                // Method called when tapping on a notification
                PushNotifications.addListener(
                    "pushNotificationActionPerformed",
                    (notification) => {
                        window.alert(
                            "Push action performed: " + JSON.stringify(notification)
                        );
                    }
                );
            } catch (e) {
                console.error("ENOTIF", e);
            }
        if (usuario._id && usuario.idCoto)
            this.coto = await Api.get("coto", usuario.idCoto);
        console.log("COTO", this.coto.nombre);
        // Firebase notifications pero sólo web, ya que getMessaging() no está soportado en webview
        if (usuario._id && Capacitor.getPlatform() === 'web')
            try {
                let fb = await fetch(`./google-services.json`);
                fb = await fb.json();
                console.info(fb);
                // Yeeei
                const init = await Api.initializeFirebase(fb);
                console.log({ init })
                await Api.wait(100);
                const token = await Api.retrieveToken();
                const yoEnDb = await Api.get("directorio", usuario._id);
                yoEnDb.fcm = yoEnDb.fcm ?? [];
                if (yoEnDb.fcm.indexOf(token) < 0) {
                    yoEnDb.fcm.push(token);
                    yoEnDb.fcm = [...new Set(yoEnDb.fcm)];
                    Api.save("directorio", yoEnDb);
                    Api.saveLocal("yo", yoEnDb);
                    window.toast("Agregado token para notificaciones");
                }
            } catch (e) {
                console.error(e);
            }
    },
    watch: {
        $route: async function (a, b) {
            console.log("$watch route", b.path, a.path, this.usuario.rol);
            this.comprobarCerrarSesion();
        },
        "usuario.rol": function (a, b) {
            if (!this.usuario.rolOriginal) this.usuario.rolOriginal = b;
            console.log(
                `Cambio de rol temporal para ${this.usuario.nombre}, de ${a} a ${b}`
            );
            Api.saveLocal("yo", this.usuario);
            window.location.reload();
        },
    },
});
</script>
